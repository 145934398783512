import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ['"Open Sans"', "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#002a61",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      tablet: 768,
      md: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        custom: {
          backgroundColor: "rgb(10	117	157)",
          borderRadius: 50,
          border: 0,
          color: "white",
          fontWeight: 600,
          ".MuiButton-endIcon": {
            
          },

          "&:hover": {
            backgroundColor: "rgb(238	114	24)",
          },
          "&.Mui-disabled": {
            backgroundColor: "#ccc",
            color: "white",
            ".MuiButton-endIcon": {
              backgroundColor: "#999",
              svg:{
                fill: "white",
              }
            },
          },
        },
        endIcon: {
          width: 32,
          height: 32,
          marginRight:"-9px",
          borderRadius: "50%",
          backgroundColor: "#fff",
          justifyContent: "center",
          alignItems: "center",

          svg:{
            fill: "rgb(238 114 24)",
          }
          
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: "hex" },
          style: {
            width: 32,
            height: 32,
            borderRadius:"50%",
            backgroundColor: "rgb(238 114 24)",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            

            "&:hover": {
              backgroundColor: "#1d2f5f",
            },
          },
        },
        {
          props: { variant: "top" },
          style: {
            width: 32,
            height: 32,
            borderRadius:"50%",
            backgroundColor: "#fff",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(238 114 24)",
            
  
            "&:hover": {
              backgroundColor: "rgb(238 114 24)",
              color: "#fff",
            },
          },
        },
      ],
      
  
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          color: "#002a61",
          "&.Mui-expanded": {
            margin: 0,
            "&:before": {
              opacity: 1,
            },
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0 10px 0 0",

          "&.Mui-expanded": {
            minHeight: "auto",
          },
          ".MuiAccordionSummary-content": {
            fontSize: "16px",
            color: "#999",
            fontWeight: 600,
            paddingRight: "20px",
          },
          ".MuiAccordionSummary-expandIconWrapper": {
            color: "#002a61",
      
          },
          "&:hover .MuiAccordionSummary-expandIconWrapper": {
            color: "rgb(238	114	24)",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0 10px 15px 0",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "rgb(238 114 24)",
          "&.Mui-checked": { color: "rgb(238 114 24)" },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#999",
          fontSize: "16px",
          fontWeight: "600",
        },
      },
    },
  },
});

export default theme;
