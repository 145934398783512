import React from 'react';
import { Container, Typography, Link } from '@mui/material';
import config from '../../config.json';
import './Header.scss';

const Header = () => {
  return (
    <>
      <header>
        <Container maxWidth='lg' className='header-container'>
          <Container maxWidth='md'>
            

          </Container>
        </Container>
      </header>
      <Typography sx={{ fontWeight: 'bold', fontSize: 18 }} className='message'>
        <div className='p1'>
        This site is intended for investigators and support team involved in Anthos Therapeutics clinical trials only and contains educational modules and scientific communications presented at congresses.
        Use the search bar or filter topics below to locate modules and materials of interest.</div>
        <div className='p2'>
        
        </div>
      </Typography>
    </>
  );
};

export default Header;
