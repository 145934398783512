import React from "react";
import { Container, Grid, Link } from "@mui/material";
import config from "../../config.json";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer id='footer'>
      <Container maxWidth='md'>
        <Grid container className='grid-container'>
          <Grid item xs={12} md={2} className='item'>
            <Link href={'https://anthostherapeutics.com'} target={'_blank'} className='logo'>
            <img src={require('../../assets/Anthos-logo.svg').default} alt='Site Logo' width={120} />
            </Link>
          </Grid>
          <Grid item xs={12} md={10} className='item copyright'>
            &copy; 2023 Anthos Therapeutics
          </Grid>
          
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
